import React, { useState, useEffect } from "react"
import { Formik, Field, Form, ErrorMessage } from "formik"
import axios from "axios"
import Success from "../Common/FormSuccess"
import Fail from "../Common/FormFail"
import Loader from "../Index/Loader"
import { useQueryParamString } from 'react-use-query-param-string';

const ContactForm = props => {
  const [SubmitSuccess, setSubmitSuccess] = useState(false)
  const [Failure, setFailure] = useState(false)
  const [loading, setLoading] = useState(false)
  const [source] = useState(props.source || 'nonsmsf');

  //oz-343 - dev- Harendra Ranasinghe
  const [firstName, setFirstName] = useQueryParamString('firstName', '');
  const [lastName, setLastName] = useQueryParamString('lastName', '');
  const [email, setEmail] = useQueryParamString('email', '');
  const [phone, setPhone] = useQueryParamString('phone', '');
  const [postCode, setPostCode] = useQueryParamString('postCode', '');
  const [gcsId] = useQueryParamString('gcsid', '');
  const [platform, setPlatform] = useQueryParamString('pf', '');
  const [organizationId, setOrganizationId] = useQueryParamString('oId', '');
  const [prospectiveBuyerId, setProspectiveBuyerId] = useQueryParamString('pbId', '');
  const [enquiryId, setEnquiryId] = useQueryParamString('enqId', '');
  const [interactionSessionId, setInteractionSessionId] = useQueryParamString('isId', '');
  const [company, setCompany] = useQueryParamString("company", "")

  useEffect(() => {
    if (SubmitSuccess || Failure) {
      const timer = setTimeout(() => {
        setSubmitSuccess(false)
        setFailure(false)
      }, 4000)
      return () => clearTimeout(timer)
    }
  }, [SubmitSuccess, Failure])

  //dev- Harendra Ranasinghe
  //get creadit score data from ocr
  //start oz-558
  useEffect(() => {
    const fetchData = async () => {
      if (gcsId) {
        setLoading(true)
        try {
          const response = await axios.get(
            `${process.env.GATSBY_BACKEND_API_URL}/partner/gcs/user/${gcsId}`
          )

          const {
            firstName = "",
            lastName = "",
            email = "",
            mobile = "",
            postcode = "",
          } = response.data || {}

          setFirstName(firstName)
          setLastName(lastName)
          setEmail(email)
          setPhone(mobile)
          setPostCode(postcode)
        } catch (error) {
          console.error("Error fetching data:", error)
        } finally {
          setLoading(false)
        }
      }
    }

    fetchData()
  }, [gcsId])
  //end oz-558

  const errorMsg = {
    color: "red",
    textAlign: "left",
  }

  const ValidateEmail = value => {
    let error
    if (!value) {
      return (error = "Required")
    }
    const RegEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!RegEx.test(value)) {
      error = "Invalid Email"
    }
    return error
  }

  const ValidateName = value => {
    let error
    if (!value) {
      return (error = "Required")
    }
    const RegEx = /^[a-zA-Z ]{2,30}$/
    if (!RegEx.test(value)) {
      error = "Invalid Name"
    }
    return error
  }

  const ValidatePostCode = value => {
    let error
    if (value) {
      const RegEx = /^[0-9]{4}$/
      if (!RegEx.test(value)) {
        error = "Invalid Postcode"
      }
    }
    return error
  }

  const ValidatePhone = value => {
    let error
    if (value.length == 12) {
      if (!value.startsWith("+")) {
        return (error = "Invalid Phone Number")
      } else {
        value = value.slice(1)
        return (error = !/^\d{11}$/.test(value) ? "Invalid Phone Number" : "")
      }
    } else if (value.length == 10) {
      if (!value.startsWith("04")) {
        return (error = "Invalid Phone Number")
      }
      const RegEx = /^\d{10}$/
      if (!RegEx.test(value)) {
        error = "Invalid Phone Number"
      }
    } else if (value.length == 0) {
      return (error = "Required")
    } else {
      return (error = "Invalid Phone Number")
    }
    return error
  }

  const ValidateAmount = value => {
    let error
    // if (!value) {
    //   return (error = "Required")
    // }
    const RegEx = /^\d{0,9}$/
    if (!RegEx.test(value)) {
      error = "Invalid Required Amount"
    }
    return error
  }

  const ValidateTermsAndConditions = value => {
    let error
    if (!value) {
      error = "Please accept terms and conditions"
    }
    return error
  }

  const resetFormValues = () => {
    setFirstName("")
    setLastName("")
    setEmail("")
    setPhone("")
    setPostCode("")
    setPlatform("")
    setOrganizationId("")
    setProspectiveBuyerId("")
    setEnquiryId("")
    setInteractionSessionId("")
    setCompany("")
  }

  const onSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true)
      if (values.LoanAmount) {
        values.LoanAmount = parseFloat(values.LoanAmount)
      } else {
        delete values.LoanAmount
      }

      values.pf = platform
      values.oid = organizationId
      values.pbid = prospectiveBuyerId
      values.enqid = enquiryId
      values.isid = interactionSessionId

      const backendAPIUrl = (() => {
        switch (company) {
          case "TheAgency":
            return process.env.GATSBY_BACKEND_API_URL_TheAgency
          case "McGrath":
            return process.env.GATSBY_BACKEND_API_URL_McGrath
          default:
            return process.env.GATSBY_BACKEND_API_URL
        }
      })()

      await axios.post(`${backendAPIUrl}/appointments/contacts`, values, {})
      setSubmitSuccess(true)
      resetForm(resetFormValues())
      if (window.pageYOffset !== 0) {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
      }
      setLoading(false)
    } catch (error) {
      console.log("error", error)
      setSubmitSuccess(false)
      setFailure(true)
      setLoading(false)
      resetForm(resetFormValues())
    }
  }

  function DynamicSubjectComponent() {
    return (
      <div className="select-wrapper">
        {(() => {
          if (source == "smsf") {
            return (
              <div className="select-wrapper">

                <Field
                  as="select"
                  name="subject"
                  id="subject"
                  className="form-control"
                  // validate={ValidateSubject}
                  placeholder="Choose SMSF Property"
                >
                  <option selected>Choose SMSF Property</option>
                  <option value="Residential">Residential</option>
                  <option value="Commercial">Commercial</option>
                </Field>
                <ErrorMessage name="subject">
                  {msg => <div style={errorMsg}>{msg}</div>}
                </ErrorMessage>
              </div>
            )
          } else {
            return (
              <div className="select-wrapper">

                <Field
                  as="select"
                  name="subject"
                  id="subject"
                  className="form-control"
                  // validate={ValidateSubject}
                  placeholder="Choose Loan Purpose"
                >
                  <option selected>Choose Loan Purpose</option>
                  <option value="Owner Occupied">Owner Occupied</option>
                  <option value="Investment">Investment</option>
                </Field>
                <ErrorMessage name="subject">
                  {msg => <div style={errorMsg}>{msg}</div>}
                </ErrorMessage>
              </div>
            )
          }
        })()}
      </div>
    );
  }

  return (
    <section className="contact-area mt-5 mb-5">
      {SubmitSuccess ? <Success /> : null}
      {Failure ? <Fail /> : null}
      {loading ? <Loader /> : null}
      <div className="mini-form-card">
        <div className="contact-form">
          <h2>{source === "smsf" ? "Free SMSF Guide & Info" : "Best Rates Start Here"}</h2>
          <Formik
            enableReinitialize
            initialValues={{
              firstName: firstName,
              lastName: lastName,
              email: email,
              phone: phone,
              message: "",
              subject: "What are you interested in?",
              postalCode: postCode,
              LoanAmount: "",
              requestedDate: "",
              requestedTimeSlot: "",
              termsConditions: false,
              ReferralPartner: props.partner,
              source: source,
              BasedWebSite:"Oxygen"
            }}
            onSubmit={onSubmit}
          >
            <Form id="contactForm">
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <Field
                      type="text"
                      id="firstName"
                      name="firstName"
                      className="form-control"
                      placeholder="First Name"
                      validate={ValidateName}
                    />
                    <ErrorMessage name="firstName">
                      {msg => <div style={errorMsg}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <Field
                      type="text"
                      id="lastName"
                      name="lastName"
                      className="form-control"
                      placeholder="Last Name"
                      validate={ValidateName}
                    />
                    <ErrorMessage name="lastName">
                      {msg => <div style={errorMsg}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <Field
                      name="email"
                      id="email"
                      className="form-control"
                      placeholder="Email"
                      validate={ValidateEmail}
                    />
                    <ErrorMessage name="email">
                      {msg => <div style={errorMsg}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <Field
                      type="tel"
                      id="phone"
                      name="phone"
                      className="form-control"
                      placeholder="Phone"
                      validate={ValidatePhone}
                    />
                    <ErrorMessage name="phone">
                      {msg => <div style={errorMsg}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <Field
                      type="text"
                      name="postalCode"
                      id="postalCode"
                      className="form-control"
                      placeholder="Postcode"
                      validate={ValidatePostCode}
                    />
                    <ErrorMessage name="postalCode">
                      {msg => <div style={errorMsg}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    {DynamicSubjectComponent()}
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <Field
                      type="text"
                      name="LoanAmount"
                      id="LoanAmount"
                      className="form-control"
                      placeholder="Required Amount"
                      validate={ValidateAmount}
                    />
                    <ErrorMessage name="LoanAmount">
                      {msg => <div style={errorMsg} className="text-align-center">{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>

                <div className="col-12">
                  <div className="remember-me-wrap">
                    <div>
                      <Field
                        type="checkbox"
                        id="termsConditions"
                        name="termsConditions"
                        className="form-control"
                        validate={ValidateTermsAndConditions}
                      />
                      <label >
                        I agree to the <a className="terms-link" href="https://oxygen.com.au/privacy" target="_blank" rel="noopener noreferrer">terms &amp; conditions</a>
                      </label>
                      <ErrorMessage name="termsConditions">
                        {msg => <div style={errorMsg}>{msg}</div>}
                      </ErrorMessage>
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <button
                    type="submit"
                    className="default-btn outline-btn"
                  >
                    {source === "smsf" ? "Email me SMSF Guide" : "Contact me for Best Rates"} <span></span>
                  </button>
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </section>
  )
}

export default ContactForm